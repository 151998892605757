// Carousel
import 'owl.carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import '../../assets/img/index/carousel-1.jpg'
import '../../assets/img/index/carousel-2.jpg'
import '../../assets/img/index/carousel-3.jpg'

export default function () {
    // Elemento donde se insertan lo botones de next y prev
    const insertBtn   = $('.insert-btn')
    const owlCarousel = $('.owl-carousel.header')

    owlCarousel.owlCarousel({
        items: 1,
        nav: true,
        loop: true,
        autoplayTimeout: 60000,
        autoplay: true,
        mouseDrag: false,
        touchDrag: false,
        navText: [
            '<span class="btn-eye border"><i class="fa fa-chevron-left fa-lg"></i></span>',
            '<span class="btn-eye border"><i class="fa fa-chevron-right fa-lg"></i></span>'
        ]
    })
        .on({
            'changed.owl.carousel': function (e) {
                $(this).find('.owl-slide-animated').removeClass('is-transitioned').end()
                    .find('.owl-item').eq(e.item.index)
                    .find('.owl-slide-animated').addClass('is-transitioned')
            },
            'initialized.owl.carousel': function () {
                $(this).find('.owl-item.active .owl-slide-animated').addClass('is-transitioned')
            }
        })
        // Corrige la posición de los backgrounds
        .trigger('refresh.owl.carousel')


    // Navegación: next and prev. del carousel
    owlCarousel.find('.owl-nav .owl-prev').detach().prependTo(insertBtn.find('.navs'))
    owlCarousel.find('.owl-nav .owl-next').detach().appendTo(insertBtn.find('.navs'))
}