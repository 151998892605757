import carousel from "./components/carousel"

// Images
import '../assets/img/projects/index-1.jpg'
import '../assets/img/projects/index-2.jpg'
import '../assets/img/breaking-news/01.jpg'
import '../assets/img/breaking-news/02.jpg'

import '../assets/img/index/bg-newsletter.jpg'

$(document).ready(function () {
    // Instancia el carousel de la pagina principal
    carousel()
})